import React, { FC } from "react";

import { ButtonProps } from "../layout/bootstrap/button/Button";
import { classNames } from "../util/class-names";

import { useFormLoading } from "./FormContext";

import styles from "./FormSubmit.module.scss";

interface FormSubmitProps extends ButtonProps {
  loading?: boolean;
}

export const FormSubmit: FC<FormSubmitProps> = ({
  className,
  children,
  ...props
}) => {
  const loading = useFormLoading();
  return (
    <button
      className={classNames(
        styles.button,
        loading && styles.loading,
        props.block && "d-block",
        className
      )}
      disabled={loading}
      type="submit"
      {...props}
    >
      {loading && (
        <div className={styles.loader}>
          <div className={`${styles.spinner} spinner-border text-primary`} />
        </div>
      )}
      <span>{children}</span>
    </button>
  );
};
