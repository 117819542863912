import React, { FC } from "react";
import styles from "./SalesHome.module.scss";
import Footer from "../shared/layout/components/Footer";

import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SecurityIcon from "@mui/icons-material/Security";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import KeyIcon from "@mui/icons-material/Key";
import Warning from "@mui/icons-material/Report";
import Check from "@mui/icons-material/Check";
import ArticleIcon from "@mui/icons-material/Article";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DevicesIcon from "@mui/icons-material/Devices";
import HeaderHome from "../../src/shared/layout/components/HeaderHome";

export const SalesHome: FC = () => {
  return (
    <>
      <HeaderHome />
      <div className={styles.allContainer}>
        <div className={styles.HeaderContainer}>
          <div className={styles.HeaderContainerDiv}>
            <div className={styles.Header}>
              <h1>DNAsec </h1>
              <h3>Programa de Segurança Corporativo</h3>
              <p>
                Conheça a sua empresa em todos os sentidos e atinja a
                conformidade de seus negócios.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.SpecsContainer}>
          <div>
            <h1>Vantagens</h1>
          </div>
          <div className={styles.SpecsItemsArea}>
            <div className={styles.SpecsItem}>
              <div className={styles.SpecsIcon}>
                <HandshakeIcon fontSize="large" />
              </div>
              <h4>Continuidade de Negócios</h4>
              <p>
                Crie resiliência empresarial com segurança, saiba como reagir às
                crises e manter suas operações em funcionamento.
              </p>
            </div>
            <div className={styles.SpecsItem}>
              <div className={styles.SpecsIcon}>
                <Warning fontSize="large" />
              </div>
              <h4>Gestão de Riscos</h4>
              <p>
                Promova o planejamento e uso dos recursos para obter o
                compliance necessário e minimizar os riscos para o seu negócio.
              </p>
            </div>
            <div className={styles.SpecsItem}>
              <div className={styles.SpecsIcon}>
                {" "}
                <AssuredWorkloadIcon fontSize="large" />
              </div>
              <h4>Auditoria</h4>
              <p>
                Avalie a conformidade das operações da organização, analisando
                se estão de acordo com as disposições planejadas e/ou
                estabelecidas previamente.
              </p>
            </div>
            <div className={styles.SpecsItem}>
              <div className={styles.SpecsIcon}>
                {" "}
                <SecurityIcon fontSize="large" />
              </div>
              <h4>Elevação na Maturidade</h4>
              <p>
                Aumente os níveis dos processos internos, identificando pontos
                de falha e definindo planos de ação para realização de ajustes
                nos controles.
              </p>
            </div>
            <div className={styles.SpecsItem}>
              <div className={styles.SpecsIcon}>
                {" "}
                <KeyIcon fontSize="large" />
              </div>
              <h4>Confidencialidade de Informações</h4>
              <p>
                Proteja os dados da sua empresa de acordo com as melhores
                tecnologias e processos.
              </p>
            </div>
            <div className={styles.SpecsItem}>
              <div className={styles.SpecsIcon}>
                {" "}
                <LocalPoliceIcon fontSize="large" />
              </div>
              <h4>Planos de Contingência</h4>
              <p>
                Prepare fluxos de urgência que sejam adequados à necessidade da
                empresa para serem seguidos em casos extraordinários.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.QualitySpecsContainer}>
          <div>
            <h1>Verticais de Consultoria</h1>
          </div>

          <div className={styles.QualitySpecDiv}>
            <div className={styles.QualitySpecItem}>
              <div className={styles.SpecsIconConsult}>
                <div className={styles.SpecsIconHead}>
                  <AssuredWorkloadIcon fontSize="large" />
                  <h4>COMPLIANCE</h4>
                </div>
                <div className={styles.SpecsIconList}>
                  <ul>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Auditoria
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      LGPD
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      GDPR
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      BACEN
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      HIPAA
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Jurídico
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.QualitySpecItem}>
              <div className={styles.SpecsIconConsult}>
                <div className={styles.SpecsIconHead}>
                  <SecurityIcon fontSize="large" />
                  <h4>CYBERSECURITY</h4>
                </div>
                <div className={styles.SpecsIconList}>
                  <ul>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Segurança de Informação
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      NIST
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      CIS Controls
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.QualitySpecItem}>
              <div className={styles.SpecsIconConsult}>
                <div className={styles.SpecsIconHead}>
                  <ArticleIcon fontSize="large" />
                  <h4>NORMATIVAS</h4>
                </div>
                <div className={styles.SpecsIconList}>
                  <ul>
                    <li>
                      <Check fontSize="small" />
                      {"  "}
                      ISO/IEC 22301
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ISO/IEC 27001
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ISO/IEC 27701
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ISO/IEC 27702
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ISO/IEC 31000
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ISO/IEC 37001
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Resolução Coaf 36 (PLD/FT)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.QualitySpecItem}>
              <div className={styles.SpecsIconConsult}>
                <div className={styles.SpecsIconHead}>
                  <PeopleAltIcon fontSize="large" />
                  <h4>GESTÃO</h4>
                </div>
                <div className={styles.SpecsIconList}>
                  <ul>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Escritório de Projetos/PMO
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Processos
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ITIL
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      ESG
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.QualitySpecItem}>
              <div className={styles.SpecsIconConsult}>
                <div className={styles.SpecsIconHead}>
                  <DevicesIcon fontSize="large" />
                  <h4>TI</h4>
                </div>
                <div className={styles.SpecsIconList}>
                  <ul>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Infraestrutura de TI
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Monitoramento
                    </li>
                    <li>
                      <Check fontSize="small" /> {"  "}
                      Engenharia de Software
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
