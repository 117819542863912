import React, { FC } from "react";
import { Link } from "react-router-dom";

import { classNames } from "../../util/class-names";

import styles from "./Header.module.scss";

interface HeaderProps {
  className?: string;
  transparent?: boolean;
}

export const Header: FC<HeaderProps> = ({
  className,
  children,
  transparent,
}) => (
  <nav
    className={`navbar navbar-expand-lg navbar-light ${styles["nav-header"]}`}
  >
    <div className={`container ${styles["container-header"]}`}>
      <div className="row">
        <Link to="/">
          <img
            className={styles.logo}
            src="/img/logo-indigo-cinza-escuro.png"
            alt="DNAsec Logo"
          />
        </Link>
      </div>
      {children}
    </div>
  </nav>
);
