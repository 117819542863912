import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../shared/auth";
import { Form } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";
import { PageLayout } from "../../shared/layout/pages/PageLayout";
import ToastAnimated, { showToast } from "../../pages/components/toast";

import styles from "./Login.module.scss";

export const Login: FC = () => {
  const auth = useAuth();

  const handleSubmit = useCallback(
    async (data) => {
      if (!data.email || !data.password) {
        showToast("error", "Confira seu e-mail e/ou sua senha");
      } else
        try {
          await auth.login("/sessions", data);
        } finally {
          const message = localStorage.getItem("@PortalMessage");
          if (message) {
            showToast("error", message);
            localStorage.clear();
          } else {
            showToast("error", "Ocorreu um erro ao fazer login");
          }
        }
    },
    [auth]
  );

  return (
    <div className={styles.root}>
      <div className={styles.cardLogin}>
        <Link to="/" className={styles.textLogo}>
          <img
            className={styles.logo}
            src="/img/logo-indigo-branco2.png"
            alt="DNAsec Logo"
          />
        </Link>
        <div className={styles.card}>
          <CardBody>
            <h2 className={styles.title}>Bem-vindo ao Portal de Canais!</h2>
            <Form onSubmit={handleSubmit} className={styles.form}>
              <FormGroup>
                <FormInput
                  className={styles.formImput}
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  autoComplete="username"
                />
              </FormGroup>
              <FormGroup inputEyeIcon>
                <FormInput
                  className={styles.formImput}
                  isPassword
                  type="password"
                  name="password"
                  placeholder="senha"
                  autoComplete="current-password"
                />
              </FormGroup>
              <FormSubmit className={styles.formSubmit} block>
                Entrar
              </FormSubmit>

              <div className="text-center mb-4">
                <Link className={styles.links} to="/register/resale">
                  Quero me cadastrar
                </Link>
              </div>
            </Form>
            <div className={styles.content}>
              <Link className={styles.links} to="/forgotPassword">
                Esqueceu sua senha?
              </Link>

              <Link className={styles.links} to="/fistAcess">
                Primeiro acesso?
              </Link>
            </div>
          </CardBody>
        </div>
      </div>
      <div className={styles.quote}></div>
      <ToastAnimated />
    </div>
  );
};
