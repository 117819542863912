import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footbar: React.FC = () => (
  <div id={styles.FootbarDiv}>
    <div className={styles.footerIcon} id={styles.logo}>
      <div className={styles.footerHeader}>
        <img
          className={styles.logo}
          src="/img/logo-indigo-branco.png"
          alt="DNAsec Logo"
        />
      </div>
    </div>
    <div className={styles.footerIcon} style={{ textAlign: "start" }}>
      <div className={styles.footerHeader}>
        <h5>Sobre</h5>
      </div>
      <div className={styles.footerItems}>
        <a target="_blank" href="https://www.dnasec.com.br/">
          Site DNAsec
        </a>
        <a target="_blank" href="#/contact">
          Entre em Contato
        </a>
        <a target="_blank" href="/#/privacypolicy">
          Política de Privacidade
        </a>
      </div>
    </div>

    <div className={styles.footerIcon} id={styles.socialMedia}>
      <div className={styles.footerHeader}>
        <h5>Redes Sociais</h5>
      </div>
      <div className={styles.footerSocial}>
        <a target="_blank" href="https://www.linkedin.com/company/dnasec/">
          <LinkedInIcon fontSize="large" />
        </a>
        <a target="_blank" href="https://www.instagram.com/dnasec_">
          <InstagramIcon fontSize="large" />
        </a>
      </div>
    </div>
  </div>
);

export default Footbar;
