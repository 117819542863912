import React, { FC, useState } from "react";
import { useLead, data } from "../../../shared/data";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import LoadingMask from "../../components/LoadingMask";
import { Card } from "../../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../../shared/layout/bootstrap/card/CardBody";
import { MainLayout } from "pages/components/MainLayout";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ToastAnimated, { showToast } from "../../components/toast";
import { phoneMask, cnpjMask, leadRepPhone } from "../../components/Masks";
import styles from "./LeadViewAdministrator.module.scss";

interface INewLead {
  user_id: String;
  companyName?: String;
  tradingName: String;
  cnpj: String;
  phone?: String;
  webSiteUrl?: String;
  city?: String;
  state?: String;
  leadRepresentative: String;
  leadRepMail: String;
  leadRepPhone: String;
  clientProblems?: String;
  clientComments?: String;
  audit?: Boolean;
  lgpd?: Boolean;
  gdpr?: Boolean;
  bacen?: Boolean;
  hipaa?: Boolean;
  legal?: Boolean;
  informationSecurity?: Boolean;
  nist?: Boolean;
  cisControls?: Boolean;
  iso22301?: Boolean;
  iso27001?: Boolean;
  iso27701?: Boolean;
  iso27702?: Boolean;
  iso31000?: Boolean;
  iso37001?: Boolean;
  coaf36?: Boolean;
  projectOfficePMO?: Boolean;
  processes?: Boolean;
  itil?: Boolean;
  esg?: Boolean;
  itInfrastructure?: Boolean;
  monitoring?: Boolean;
  softwareEngineering?: Boolean;
  status: String;
}

export const LeadViewAdministrator: FC<ViewComponentProps> = ({
  match: { params },
}) => {
  const lead = useLead(params.id);

  let readOnlyLead = false;

  if (lead && (lead.status === "APROVADO" || lead.status === "REPROVADO")) {
    readOnlyLead = true;
  }

  const [statusLead, setStatusLead] = useState(String);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Campo obrigatório"),
    //tradingName: Yup.string().required("Campo obrigatório"),
    cnpj: Yup.string().required("Campo obrigatório"),
    leadRepresentative: Yup.string().required("Campo obrigatório"),
    leadRepMail: Yup.string().required("Campo obrigatório"),
    leadRepPhone: Yup.string().required("Campo obrigatório"),
    webSiteUrl: Yup.string().required("Campo obrigatório"),
    city: Yup.string().required("Campo obrigatório"),
    state: Yup.string().required("Campo obrigatório"),
    phone: Yup.string().required("Campo obrigatório"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;

  const history = useHistory();

  const onSubmit = (formData) => {
    const dataLead: INewLead = {
      ...formData,
      id: params.id,
      user_id: lead.user_id,
      status: statusLead,
    };

    let isError = false;

    if (
      !dataLead.audit &&
      !dataLead.lgpd &&
      !dataLead.gdpr &&
      !dataLead.bacen &&
      !dataLead.hipaa &&
      !dataLead.legal &&
      !dataLead.informationSecurity &&
      !dataLead.nist &&
      !dataLead.cisControls &&
      !dataLead.iso22301 &&
      !dataLead.iso27001 &&
      !dataLead.iso27701 &&
      !dataLead.iso27702 &&
      !dataLead.iso31000 &&
      !dataLead.iso37001 &&
      !dataLead.coaf36 &&
      !dataLead.projectOfficePMO &&
      !dataLead.processes &&
      !dataLead.itil &&
      !dataLead.esg &&
      !dataLead.itInfrastructure &&
      !dataLead.monitoring &&
      !dataLead.softwareEngineering
    ) {
      isError = true;
      showToast("error", "Selecione a vertical de consultoria.");
    }
    if (!isError) {
      setLoading(true);
      data
        .patch("/leadAdministrator", dataLead)
        .then(() => {
          setLoading(false);
          history.push("/approve/leads");
        })
        .catch((err) => {
          setLoading(false);
          console.log("erro:", err);
        });
    } else {
    }
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  const [state, setState] = useState({
    phone: "",
    leadRepPhone: "",
    cnpj: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <MainLayout title="Visualizar Lead" arrowBack>
      {loading ? <LoadingMask text="Salvando..." /> : null}
      <ToastAnimated />
      {lead ? (
        <Card>
          <CardBody>
            <div className="col-md-12">
              <div className="col-md-10"></div>

              <FloatingLabel
                controlId="floatingTextarea"
                label="Status"
                className="mb-3"
              >
                <Form.Control value={lead.status} type="text" readOnly />
              </FloatingLabel>
            </div>
            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <h6 className="mt-4 mb-2 pb-3">DADOS DA EMPRESA</h6>
              <Row>
                <div className="col-md-6">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Razão Social"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      defaultValue={lead.companyName}
                      readOnly={readOnlyLead}
                      {...register("companyName")}
                    />
                    {errors.companyName && (
                      <div className={styles.errorStyling}>
                        <span>{errors.companyName.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Nome Fantasia"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      readOnly={readOnlyLead}
                      defaultValue={lead.tradingName}
                      {...register("tradingName")}
                    />
                  </FloatingLabel>
                </div>
              </Row>
              <Row>
                <div className="col-md-6">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="CNPJ"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="cnpj"
                      readOnly={true}
                      defaultValue={lead.cnpj}
                      {...register("cnpj")}
                      onChange={(e) => handleChange(cnpjMask(e))}
                    />

                    {errors.cnpj && (
                      <div className={styles.errorStyling}>
                        <span>{errors.cnpj.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Telefone"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="phone"
                      readOnly={readOnlyLead}
                      defaultValue={lead.phone}
                      {...register("phone")}
                      onChange={(e) => handleChange(phoneMask(e))}
                    />
                    {errors.phone && (
                      <div className={styles.errorStyling}>
                        <span>{errors.phone.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
              </Row>
              <Row>
                <div className="col-md-5">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Site"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      readOnly={readOnlyLead}
                      defaultValue={lead.webSiteUrl}
                      {...register("webSiteUrl")}
                    />

                    {errors.webSiteUrl && (
                      <div className={styles.errorStyling}>
                        <span>{errors.webSiteUrl.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
                <div className="col-md-5">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Cidade"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      readOnly={readOnlyLead}
                      defaultValue={lead.city}
                      {...register("city")}
                    />

                    {errors.city && (
                      <div className={styles.errorStyling}>
                        <span>{errors.city.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
                <div className="col-md-2">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="UF"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      readOnly={readOnlyLead}
                      defaultValue={lead.state}
                      {...register("state")}
                    />

                    {errors.state && (
                      <div className={styles.errorStyling}>
                        <span>{errors.state.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
              </Row>
              <h6 className="mt-3 mb-2 pb-3">REPRESENTANTE DA EMPRESA</h6>
              <Row>
                <div className="col-md-4">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Representante"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      readOnly={readOnlyLead}
                      defaultValue={lead.leadRepresentative}
                      {...register("leadRepresentative")}
                    />

                    {errors.leadRepresentative && (
                      <div className={styles.errorStyling}>
                        <span>{errors.leadRepresentative.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
                <div className="col-md-4">
                  <FloatingLabel
                    controlId="formBasicEmail"
                    label="E-mail"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      readOnly={readOnlyLead}
                      defaultValue={lead.leadRepMail}
                      {...register("leadRepMail", {
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                      })}
                    />
                    {errors.leadRepMail && (
                      <div className={styles.errorStyling}>
                        <span>{errors.leadRepMail.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
                <div className="col-md-4">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Telefone"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="leadRepPhone"
                      {...register("leadRepPhone")}
                      readOnly={readOnlyLead}
                      defaultValue={lead.leadRepPhone}
                      onChange={(e) => handleChange(leadRepPhone(e))}
                    />

                    {errors.leadRepPhone && (
                      <div className={styles.errorStyling}>
                        <span>{errors.leadRepPhone.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>
              </Row>

              <h6 className="mt-3 mb-2 pb-3">VERTICAIS DE CONSULTORIA</h6>
              <Row className="">
                <div className="col-md-4 pb-3">
                  <h6>Compliance</h6>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="defaultCheck1"
                      disabled={readOnlyLead}
                      defaultChecked={lead.audit}
                      {...register("audit")}
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      Auditoria
                    </label>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.lgpd}
                        {...register("lgpd")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        LGPD
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.gdpr}
                        {...register("gdpr")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        GDPR
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.bacen}
                        {...register("bacen")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        BACEN
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.hipaa}
                        {...register("hipaa")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        HIPAA
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.legal}
                        {...register("legal")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Jurídico
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pb-3">
                  <h6>CyberSecurity</h6>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.informationSecurity}
                        {...register("informationSecurity")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Segurança de Informação
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.nist}
                        {...register("nist")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        NIST
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.cisControls}
                        {...register("cisControls")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        CIS Controls
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <h6>Normativas</h6>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.iso22301}
                        {...register("iso22301")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ISO/IEC 22301
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.iso27001}
                        {...register("iso27001")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ISO/IEC 27001
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={readOnlyLead}
                        defaultChecked={lead.iso27701}
                        {...register("iso27701")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ISO/IEC 27701
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.iso27702}
                        {...register("iso27702")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ISO/IEC 27702
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.iso31000}
                        {...register("iso31000")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ISO/IEC 31000
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.iso37001}
                        {...register("iso37001")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ISO/IEC 37001
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.coaf36}
                        {...register("coaf36")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Resolução Coaf 36 (PLD/FT)
                      </label>
                    </div>
                  </div>
                </div>
              </Row>

              <Row className="mt-3 mb-2 pb-3">
                <div className="col-md-4 pb-3">
                  <h6>Gestão</h6>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.projectOfficePMO}
                        {...register("projectOfficePMO")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Escritório de Projetos/PMO
                      </label>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.processes}
                        {...register("processes")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Processos
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.itil}
                        {...register("itil")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ITIL
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.esg}
                        {...register("esg")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        ESG
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <h6>TI</h6>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.itInfrastructure}
                        {...register("itInfrastructure")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Infraestrutura de TI
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.monitoring}
                        {...register("monitoring")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Monitoramento
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyLead}
                        defaultChecked={lead.softwareEngineering}
                        {...register("softwareEngineering")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Engenharia de Software
                      </label>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mt-3 mb-2 pb-3">
                <h6 className="mt-3 mb-2 pb-3">OUTRAS INFORMAÇÕES</h6>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                      Descreva o(s) problema(s) do cliente:
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      readOnly={readOnlyLead}
                      defaultValue={lead.clientProblems}
                      {...register("clientProblems")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                      Comentários:
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      defaultValue={lead.clientComments}
                      readOnly={readOnlyLead}
                      {...register("clientComments")}
                    />
                  </div>
                </div>
              </Row>

              <div>
                <Button
                  variant="danger"
                  type="submit"
                  className={styles.Button}
                  onClick={() => {
                    setStatusLead("REPROVADO");
                  }}
                >
                  Reprovar
                </Button>

                <Button
                  variant="success"
                  type="submit"
                  onClick={() => {
                    setStatusLead("APROVADO");
                  }}
                >
                  Tornar uma oportunidade
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      ) : (
        <h6>Carregando...</h6>
      )}
    </MainLayout>
  );
};
