import React, { FC, useState } from "react";

import { useOpportunity } from "../../shared/data";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneMask, cnpjMask, leadRepPhone } from "../components/Masks";
import { MainLayout } from "pages/components/MainLayout";
import styles from "./OpportunityView.module.scss";

export const OpportunityView: FC<ViewComponentProps> = ({ match }) => {
  const [params] = useState<OpportunityKey>(match.params);
  const opportunity = useOpportunity(params);
  let readOnlyOpportunity = true;

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Campo obrigatório"),
    //tradingName: Yup.string().required("Campo obrigatório"),
    cnpj: Yup.string().required("Campo obrigatório"),
    leadRepresentative: Yup.string().required("Campo obrigatório"),
    leadRepMail: Yup.string().required("Campo obrigatório"),
    leadRepPhone: Yup.string().required("Campo obrigatório"),
    webSiteUrl: Yup.string().required("Campo obrigatório"),
    city: Yup.string().required("Campo obrigatório"),
    state: Yup.string().required("Campo obrigatório"),
    phone: Yup.string().required("Campo obrigatório"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (formData) => {};

  const onError = (errors: any, e: any) => console.log(errors, e);

  const [state, setState] = useState({
    phone: "",
    leadRepPhone: "",
    cnpj: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <MainLayout title="Visualizar Oportunidade" arrowBack>
      {opportunity ? (
        <Card>
          <CardBody>
            <div className="col-md-12">
              <Form onSubmit={handleSubmit(onSubmit, onError)}>
                <h6 className="mt-3 mb-2 pb-3">DADOS DA EMPRESA</h6>
                <Row>
                  <div className="col-md-6">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Razão Social"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={opportunity.companyName}
                        readOnly={readOnlyOpportunity}
                        {...register("companyName")}
                      />
                      {errors.companyName && (
                        <div className={styles.errorStyling}>
                          <span>{errors.companyName.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                  <div className="col-md-6">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Nome Fantasia"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.tradingName}
                        {...register("tradingName")}
                      />
                    </FloatingLabel>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="CNPJ"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="cnpj"
                        readOnly={true}
                        defaultValue={opportunity.cnpj}
                        {...register("cnpj")}
                        onChange={(e) => handleChange(cnpjMask(e))}
                      />

                      {errors.cnpj && (
                        <div className={styles.errorStyling}>
                          <span>{errors.cnpj.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                  <div className="col-md-6">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Telefone"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="phone"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.phone}
                        {...register("phone")}
                        onChange={(e) => handleChange(phoneMask(e))}
                      />
                      {errors.phone && (
                        <div className={styles.errorStyling}>
                          <span>{errors.phone.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Site"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.webSiteUrl}
                        {...register("webSiteUrl")}
                      />

                      {errors.webSiteUrl && (
                        <div className={styles.errorStyling}>
                          <span>{errors.webSiteUrl.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Cidade"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.city}
                        {...register("city")}
                      />

                      {errors.city && (
                        <div className={styles.errorStyling}>
                          <span>{errors.city.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                  <div className="col-md-2">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="UF"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.state}
                        {...register("state")}
                      />

                      {errors.state && (
                        <div className={styles.errorStyling}>
                          <span>{errors.state.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                </Row>
                <h6 className="mt-3 mb-2 pb-3">REPRESENTANTE DA EMPRESA</h6>
                <Row>
                  <div className="col-md-4">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Representante"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.leadRepresentative}
                        {...register("leadRepresentative")}
                      />

                      {errors.leadRepresentative && (
                        <div className={styles.errorStyling}>
                          <span>{errors.leadRepresentative.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                  <div className="col-md-4">
                    <FloatingLabel
                      controlId="formBasicEmail"
                      label="E-mail"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.leadRepMail}
                        {...register("leadRepMail", {
                          pattern:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                        })}
                      />
                      {errors.leadRepMail && (
                        <div className={styles.errorStyling}>
                          <span>{errors.leadRepMail.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                  <div className="col-md-4">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Telefone"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="leadRepPhone"
                        {...register("leadRepPhone")}
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.leadRepPhone}
                        onChange={(e) => handleChange(leadRepPhone(e))}
                      />

                      {errors.leadRepPhone && (
                        <div className={styles.errorStyling}>
                          <span>{errors.leadRepPhone.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                </Row>

                <h6 className="mt-3 mb-2 pb-3">VERTICAIS DE CONSULTORIA</h6>
                <Row className="">
                  <div className="col-md-4 pb-3">
                    <h6>Compliance</h6>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        disabled={readOnlyOpportunity}
                        defaultChecked={opportunity.audit}
                        {...register("audit")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Auditoria
                      </label>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.lgpd}
                          {...register("lgpd")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          LGPD
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.gdpr}
                          {...register("gdpr")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          GDPR
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.bacen}
                          {...register("bacen")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          BACEN
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.hipaa}
                          {...register("hipaa")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          HIPAA
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.legal}
                          {...register("legal")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Jurídico
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 pb-3">
                    <h6>CyberSecurity</h6>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.informationSecurity}
                          {...register("informationSecurity")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Segurança de Informação
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.nist}
                          {...register("nist")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          NIST
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.cisControls}
                          {...register("cisControls")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          CIS Controls
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <h6>Normativas</h6>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.iso22301}
                          {...register("iso22301")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ISO/IEC 22301
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.iso27001}
                          {...register("iso27001")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ISO/IEC 27001
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.iso27701}
                          {...register("iso27701")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ISO/IEC 27701
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.iso27702}
                          {...register("iso27702")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ISO/IEC 27702
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.iso31000}
                          {...register("iso31000")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ISO/IEC 31000
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.iso37001}
                          {...register("iso37001")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ISO/IEC 37001
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.coaf36}
                          {...register("coaf36")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Resolução Coaf 36 (PLD/FT)
                        </label>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row className="mt-3 mb-2 pb-3">
                  <div className="col-md-4 pb-3">
                    <h6>Gestão</h6>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.projectOfficePMO}
                          {...register("projectOfficePMO")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Escritório de Projetos/PMO
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.processes}
                          {...register("processes")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Processos
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.itil}
                          {...register("itil")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ITIL
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.esg}
                          {...register("esg")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ESG
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <h6>TI</h6>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.itInfrastructure}
                          {...register("itInfrastructure")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Infraestrutura de TI
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.monitoring}
                          {...register("monitoring")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Monitoramento
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          disabled={readOnlyOpportunity}
                          defaultChecked={opportunity.softwareEngineering}
                          {...register("softwareEngineering")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Engenharia de Software
                        </label>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="mt-3 mb-2 pb-3">
                  <h6 className="mt-3 mb-2 pb-3">OUTRAS INFORMAÇÕES</h6>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">
                        Descreva o(s) problema(s) do cliente:
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        readOnly={readOnlyOpportunity}
                        defaultValue={opportunity.clientProblems}
                        {...register("clientProblems")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">
                        Comentários:
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        defaultValue={opportunity.clientComments}
                        readOnly={readOnlyOpportunity}
                        {...register("clientComments")}
                      />
                    </div>
                  </div>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      ) : (
        <h6>Carregando...</h6>
      )}
    </MainLayout>
  );
};
