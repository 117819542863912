import React, { ChangeEvent, FC, useContext, useState } from "react";
import { IMaskInput } from "react-imask";

import { InputGroup } from "../layout/bootstrap/form";

import { FormContext, FormValue } from "./FormContext";
import { HiEye, HiEyeOff } from "react-icons/hi";

import styles from "./FormInput.module.scss";

interface FormInputProps {
  className?: string;
  autoComplete?: string;
  label?: string;
  name?: string;
  mask?: string;
  onAccept?: (_: FormValue) => void;
  onChange?: (_: FormValue) => void;
  onValue?: (_: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  type?: string;
  value?: string;
  placeholder?: string;
  isPassword?: boolean;
}

const masks = {
  telephone: {
    mask: "(00) 00000-0000",
  },
  date: {
    mask: "00/00/0000",
  },
  cpf: {
    mask: "000.000.000-00",
  },
  cep: {
    mask: "00000-000",
  },
  cnpj: {
    mask: "00.000.000/0000-00",
  },
  currency: {
    mask: Number,
    padFractionalZeros: true,
    radix: ",",
    scale: 2,
    signed: false,
    thousandsSeparator: ".",
    style: {
      textAlign: "right",
    },
  },
  percent: {
    mask: Number,
    normalizeZeros: true,
    radix: ",",
    scale: 2,
    min: 0,
    max: 100,
    style: {
      textAlign: "right",
    },
  },
  phone: {
    mask: [
      {
        mask: "(00) 00000-0000",
      },
      {
        mask: "(00) 00000-0000",
      },
    ],
    dispatch: (appended, dispatcher) => {
      const digit = `${dispatcher.value}${appended}`
        .replace(/[^\d]/g, "")
        .charAt(2);
      if (digit === "9") {
        return dispatcher.compiledMasks[0];
      } else {
        return dispatcher.compiledMasks[1];
      }
    },
  },
};

export const FormInput: FC<FormInputProps> = ({
  className,
  onAccept,
  onChange,
  name,
  mask,
  value,
  placeholder,
  onValue,
  isPassword,
  type,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const form = useContext(FormContext);
  const [current, setCurrent] = useState<string>(
    (form.get?.(name) as string) || value || ""
  );
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    form.set && form.set(name, event.target.value);
    setCurrent(event.target.value);
    onValue?.(event.target.value);
  };
  const input = {
    className: `form-control ${isPassword ? styles.formInput : ""}`,
    readOnly: form.readOnly,
    value: current,
    disabled: props.disabled,
  };

  if (mask) {
    const maskProps = {
      onAccept: (value: string) => {
        form.set(name, value);
        setCurrent(value);
        onAccept && onAccept(value);
        onValue?.(value);
      },
      unmask: true,
      value: current,
      ...masks[mask],
    };
    const prepend = () => {
      if (mask === "currency") {
        return <span className="input-group-text">R$</span>;
      }
    };
    const append = () => {
      if (mask === "percent") {
        return <span className="input-group-text">%</span>;
      }
    };
    return (
      <InputGroup append={append()} prepend={prepend()}>
        <IMaskInput {...input} {...maskProps} placeholder={placeholder} />
      </InputGroup>
    );
  }

  const handleClick = (e) => {
    e.preventDefault();
    setShow(!show);
  };
  return (
    <>
      <input
        onChange={handleChange}
        type={isPassword && show ? "text" : type}
        {...input}
        {...props}
        placeholder={placeholder}
        className={className}
      />
      {isPassword && (
        <div className={styles.inputIcon}>
          {show ? (
            <HiEye onClick={handleClick} />
          ) : (
            <HiEyeOff onClick={handleClick} />
          )}
        </div>
      )}
    </>
  );
};
