import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import styles from "./HeaderHome.module.scss";

const HeaderHome: React.FC = () => (
  <>
    <nav
      className={`navbar navbar-expand-lg navbar-light ${styles["nav-header"]}`}
    >
      <div className={`container ${styles["container-header"]}`}>
        <div className="row">
          <Link to="/">
            <img
              className={styles.logo}
              src="/img/logo-indigo-cinza-escuro.png"
              alt="DNAsec Logo"
            />
          </Link>
        </div>
        <div className="d-flex flex-row gap-1">
          <a href="#/register/resale" className={styles.registerButton}>
            Seja um Canal
          </a>{" "}
          <a href="#/login" className={styles.loginButton}>
            Login do Canal
          </a>{" "}
        </div>
      </div>
    </nav>
  </>
);

export default HeaderHome;
