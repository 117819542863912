import React, { FC, useState, useEffect } from "react";
import { data, useResale } from "../../../shared/data";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { EResaleCategory } from "../../../shared/data/enums";
import { zipCodeMask } from "../../../pages/components/Masks";
import FormGroup from "react-bootstrap/FormGroup";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "react-bootstrap/Button";
import { Card } from "../../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../../shared/layout/bootstrap/card/CardBody";
import { MainLayout } from "pages/components/MainLayout";
import { useForm } from "react-hook-form";
import findZipCode from "../../../shared/api/viacep";

interface IResale {
  id: string;
  name: string;
  trading_name: string;
  register_number: string;
  state_registration?: string;
  municipal_registration?: string;
  zipcode?: string;
  address?: string;
  number?: number;
  complement?: string;
  district?: string;
  city?: string;
  state?: string;
  status: string;
  resale_category: string;
}

export interface Izipcode {
  cep: string;
  bairro: string;
  complemento: string;
  ddd: string;
  localidade: string;
  logradouro: string;
  uf: string;
}

export const ResaleView: FC<ViewComponentProps> = ({ match }) => {
  const resale = useResale(match.params);

  const [status, setStatus] = useState(String);

  const validationSchema = Yup.object().shape({
    register_number: Yup.string().required("Campo obrigatório"),
    trading_name: Yup.string().required("Campo obrigatório"),
    resale_category: Yup.string()
      .required("Selecione o nível de parceria.")
      .nullable(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue, setFocus } =
    useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (formData) => {
    const dataResale: IResale = {
      ...formData,
      status: status,
    };

    data
      .patch(`/resales/${resale.id}`, dataResale)
      .then(() => {
        alert("Atualizado");
        window.location.assign("#resales");
      })
      .catch(() => alert("Falha"));
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  const errorStyling = {
    color: "red",
    fontSize: 12,
  };

  const partnerStyling = {
    display: "flex",
  };

  const [zipCode, setZipCode] = useState();

  const checkZipCode = async (e) => {
    if (!e.target.value) return;
    const zipCode = e.target.value.replace(/\D/g, "");
    if (zipCode.length === 8) {
      setZipCode(zipCode);
    }
  };

  useEffect(() => {
    async function getCep() {
      if (zipCode) {
        try {
          const data: Izipcode | any = await findZipCode(zipCode);
          setValue("zipcode", data.cep);
          setValue("address", data.logradouro);
          setValue("district", data.bairro);
          setValue("city", data.localidade);
          setValue("state", data.uf);
          setFocus("number");
        } catch (error) {
          console.log("Ocorreu um erro ao buscar os itens");
        }
      }
    }
    getCep();
  }, [zipCode, setValue, setFocus]);

  return (
    <MainLayout title="Visualizar Canal" arrowBack>
      {resale && (
        <Card>
          <CardBody>
            <div>
              <Form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="row">
                  <div className="col-12 ">
                    <h6 className="mb-3 mt-4">DADOS DA EMPRESA</h6>
                  </div>
                  <div className="col-md-6">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Razão Social"
                      className="mb-3"
                    >
                      <Form.Control
                        defaultValue={resale.name}
                        type="text"
                        {...register("name")}
                      />
                    </FloatingLabel>
                  </div>

                  <div className="col-md-6">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Nome Fantasia"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.trading_name}
                        {...register("trading_name")}
                      />

                      {errors.trading_name && (
                        <div style={errorStyling}>
                          <span>{errors.trading_name.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 ">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="CNPJ"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="register_number"
                        readOnly
                        value={resale.register_number}
                        {...register("register_number")}
                      />

                      {errors.register_number && (
                        <div style={errorStyling}>
                          <span>{errors.register_number.message}</span>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>

                  <div className="col-md-4">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Inscrição Estadual"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.state_registration}
                        {...register("state_registration")}
                      />
                    </FloatingLabel>
                  </div>

                  <div className="col-md-4">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Inscrição Municipal"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.municipal_registration}
                        {...register("municipal_registration")}
                      />
                    </FloatingLabel>
                  </div>

                  <div className="col-md-12">
                    <div className="col-md-2">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Status"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          readOnly
                          value={resale.status}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <h6 className="mb-3 mt-4">ENDEREÇO</h6>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="CEP"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.zipcode}
                        {...register("zipcode")}
                        onBlur={checkZipCode}
                        onChange={(e) => zipCodeMask(e)}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Rua"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.address}
                        {...register("address", { required: false })}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col-md-2">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Número"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        defaultValue={resale.number}
                        {...register("number", { required: false })}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col-md-3">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Complemento"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.complement}
                        {...register("complement", { required: false })}
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Bairro"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.district}
                        {...register("district")}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Cidade"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.city}
                        {...register("city")}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col-md-2">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="UF"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={resale.state}
                        {...register("state")}
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <div className="col-12 mt-4 pb-3">
                  <h6 className="mt-3 mb-2 pb-3">
                    NÍVEL DE PARCERIA DESEJADO PARA APLICAÇÃO:
                  </h6>

                  <FormGroup className="col-12 mb-3">
                    {errors.resale_category && (
                      <div style={errorStyling}>
                        <span>{errors.resale_category.message}</span>
                      </div>
                    )}

                    <div style={partnerStyling}>
                      {Array.from(EResaleCategory.entries()).map(
                        ([value, label]) => (
                          <Form.Check
                            key={value}
                            type="radio"
                            name="resale_category"
                            label={label}
                            className="col-4 mb-3"
                            defaultChecked={
                              resale.resale_category === value.toString()
                            }
                            value={value}
                            {...register("resale_category", { required: true })}
                          />
                        )
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <Button
                    variant="danger"
                    type="submit"
                    onClick={() => {
                      setStatus("REPROVADO");
                    }}
                  >
                    REPROVAR
                  </Button>
                  <Button
                    variant="success"
                    type="submit"
                    onClick={() => {
                      setStatus("APROVADO");
                    }}
                  >
                    APROVAR
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
